import React, { useState } from 'react';
import './Login_Pesquisador.css';
import { useNavigate } from 'react-router-dom';

const LoginPesquisador = () => {

    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({ //armazena os valores dos campos
        email: '',
        senha: '',
    });

    const handleChange = (e) => { //atualiza o formData conforme o usuario digita
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => { //envia os dados, imprimindo na tela
        e.preventDefault();
        console.log('Dados do formulário:', formData);
    };

    return (
        <div className="login-container">
            <img src="https://i.ibb.co/SVxk8Mc/logo-removebg-preview.png" alt="Logo" className="logo" /> {/* Substitua "logo.png" pelo caminho da sua logo */}
            <form onSubmit={handleSubmit} className="login-form">
                <h2>LOGIN PESQUISADOR</h2>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        name="senha"
                        placeholder="Senha"
                        value={formData.senha}
                        onChange={handleChange}
                        required
                    />
                </div>
                <a><button type="submit" className="submit-button">Login</button></a>
            </form>
            <p className="login-text">
                Não tem conta?
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault(); // Impede o comportamento padrão do link
                        navigate('/Cadastro_Pesquisador');
                    }}
                >
                    Cadastre-se!
                </a>
            </p>
        </div>
    );
};

export default LoginPesquisador;
