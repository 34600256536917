import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import Cadastro_Visitante from './components/Cadastro_Visitante';
import Login_Visitante from './components/Login_Visitante';
import Cadastro_Pesquisador from './components/Cadastro_Pesquisador';
import Login_Pesquisador from './components/Login_Pesquisador';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cadastrar_Pesquisa from './components/Cadastrar_Pesquisa';

const App = () => {
  return (
      <Router>
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header />
          <main style={{ flex: '1' }}>
            <Routes>
              {/* Defina as rotas principais aqui */}
              <Route path="/" element={< MainContent/>} />
              <Route path="/Login_Visitante" element={<Login_Visitante/>} />
              <Route path="/Cadastro_Visitante" element={<Cadastro_Visitante/>} />
              <Route path="/Login_Pesquisador" element={<Login_Pesquisador/>} />
              <Route path="/Cadastro_Pesquisador" element={<Cadastro_Pesquisador/>} />
              <Route path="/Cadastrar_Pesquisa" element={<Cadastrar_Pesquisa/>} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
  );
};

export default App;