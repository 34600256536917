import React, { useState } from 'react';
import './Cadastrar_Pesquisa.css';
import { useNavigate } from 'react-router-dom';

const CadastrarPesquisa = () => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({ //armazena os valores dos campos
        nomePesquisa: '',
        localizacao: '',
        nomePesquisador: '',
        instituicao: '',
    });

    const handleChange = (e) => { //atualiza o formData conforme o usuario digita
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => { //envia os dados, imprimindo na tela
        e.preventDefault();
        console.log('Dados do formulário:', formData);
    };

    return (
        <div className="cadastro-pesquisa-container">
            <h1>CADASTRE SUA PESQUISA:</h1>
            <p>
                Informe os dados da sua pesquisa e tenha acesso aos resultados de forma
                personalizada e a insights inteligentes.
            </p>
            <div className="form-container">
                <form>
                <div className="containerI">
                <div className="form-groupII">
                    <input
                        type="text"
                        name="nomePesquisa"
                        placeholder="Nome da Pesquisa"
                        value={formData.nomePesquisa}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-groupII">
                    <input
                        type="text"
                        name="localizacao"
                        placeholder="Localizacao"
                        value={formData.localizacao}
                        onChange={handleChange}
                        required
                    />
                </div>
                </div>
                <div className='containerII'>
                <div className="form-groupII">
                    <input
                        type="text"
                        name="nomePesquisador"
                        placeholder="Nome do Pesquisador"
                        value={formData.nomePesquisador}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-groupII">
                    <input
                        type="text"
                        name="instituicao"
                        placeholder="Instituição"
                        value={formData.instituicao}
                        onChange={handleChange}
                        required
                    />
                </div>
                </div>
                    <button type="submit" className="submit-button">CADASTRAR PESQUISA</button>
                </form>
            </div>
        </div>
    );

};

export default CadastrarPesquisa;