import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './Footer.css';

const Footer = () => {
  return (
    <Box 
    component="footer" 
    className="footer" 
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, padding: 2, marginTop: 'auto' }}
    >
      <Button 
        variant="text" 
        onClick={() => {
          // Adicione a navegação ou ação desejada aqui
        }}
      >
        Políticas de Privacidade e Termos de Uso
      </Button>
      <Typography variant="body2" align="center">
        2024 IPAQmove. Todos os direitos reservados.
      </Typography>
    </Box>
  );
};

export default Footer;
