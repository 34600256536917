import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.css';
import { CiUser } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

  const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navigate = useNavigate();

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        <ListItem button>
          <ListItemText primary="TELA INICIAL" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="RESULTADOS" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="CADASTRAR PESQUISA" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="IPAQ" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '150px' }}>
          <img src="https://i.ibb.co/SVxk8Mc/logo-removebg-preview.png" alt="IPAQMove Logo" style={{ weight: '10px', height: 'auto', margin: '0' }} />
          <h2 style={{ color: '#032D45', fontWeight: 'bold' }}>IPAQ<span style={{ color: '#15E2C3' }}>move</span></h2>
        </div>
        <nav className="navMenu">
          <ul>
            <li><a onClick={() => navigate('/')}>TELA INICIAL</a></li>
            <li>RESULTADOS</li>
            <li><a onClick={() => navigate('/Cadastrar_Pesquisa')}>CADASTRAR PESQUISA</a></li>
            <li>IPAQ</li>
          </ul>
        </nav>
        <CiUser
          style={{ width: 'auto', height: '5vh', paddingLeft: '160px', cursor: 'pointer' }}
          onClick={() => navigate('/Login_Visitante')} // Redireciona para login ao clicar
        />
      </Toolbar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;
