import React from 'react';
import { Grid, Box, Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';


const PageLayout = () => {

  const navigate = useNavigate();

  return (
    <Box sx={{ width: '98.9vw', minHeight: '100vh', padding: '0'}}>
      <Grid container direction="column" spacing={1}>

        {/* Section 1 */}
          <Box sx={{ background: 'linear-gradient(45deg, #FFFFFF, #ADECE9)', textAlign: 'center', width: '100vw'}}>
            <Paper elevation={3} sx={{ padding: 3, paddingLeft: 20, paddingRight: 20, textAlign: 'center', background: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40vh' }}>
              <div style={{ marginRight: '20px', width: '50%' }}>
                <Typography variant="h6" gutterBottom>
                  Avalie o seu nível de atividade física através do Questionário Internacional de Atividade Física (IPAQ).
                </Typography>
                <Button variant="contained" style={{ marginTop: '20px', background: '#032D45', color: '#FFFFFF', borderRadius: '20px', fontWeight: 'bold' }}>
                  CONHEÇA O IPAQ
                </Button>
              </div>
              <a href="https://imgbb.com/" style={{ display: 'block' }}>
                <img src="https://i.ibb.co/mGZ2Qzy/imgintro.png" alt="imgintro" style={{ width: 'auto', height: '40vh'}} />
              </a>
            </Paper>
          </Box>


        {/* Section 2 */}
          <Box sx={{textAlign: 'center', width: '100vw' }}>
            <Paper elevation={3} sx={{ padding: 3, paddingRight: 20, paddingLeft: 20, textAlign: 'center', background: 'linear-gradient(45deg, #032D45, #1AB9B7)', color: 'white', height: '40vh', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center'}}>
              <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                Explore um Mundo de Bem-Estar e Eficiência com Nossos Serviços Digitais
              </Typography>
              <Typography variant="body1" gutterBottom style={{ display: 'flex', textAlign: 'center' }}>
              Seja bem vindo ao seu primeiro passo em direção a melhor versão da sua saúde e bem- estar. 
              Através dos nossos serviços os USUÁRIOS terão acesso a diferentes soluções para transformar 
              sua saúde através da prática de atividades físicas. E os PESQUISADORES terão acesso a insights inteligentes para suas pesquisas.
              </Typography>
              <Button variant="contained" color="secondary" style={{ marginTop: '20px', background: '#032D45', color: '#FFFFFF', borderRadius: '20px', fontWeight: 'bold' }}>
                COMECE AQUI!
              </Button>
            </Paper>
          </Box>


        {/* Section 3 */}
          <Box sx={{ background: 'linear-gradient(45deg, #FFFFFF, #ADECE9)', display: 'flex', flexDirection: 'row', width: '100vw'}}>
            <Paper elevation={3} sx={{ padding: 3, paddingLeft: 20, paddingRight: 20, textAlign: 'center', background: 'none', height: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{ marginRight: '20px', width: '50%' }}>
              <Typography variant="h5" gutterBottom style={{fontWeight: 'bold', color: '#032D45'}}>
                Visualize e Gerencie Seus Resultados de Forma Eficaz
              </Typography>
              <Typography variant="body1" gutterBottom>
              PESQUISADOR, visualize os resultados da(s) sua(s) pesquisa(s) de forma simples e personalizada, e tenha acesso a insights inteligentes
              </Typography>
              <Button variant="contained" style={{ marginTop: '20px', background: '#032D45', color: '#FFFFFF', borderRadius: '20px', fontWeight: 'bold' }}>
                UTILIZE NOSSAS FERRAMENTAS
              </Button>
              </div>
              <a href="https://imgbb.com/" style={{ display: 'block' }}>
                <img src="https://i.ibb.co/kGLd3Ct/results-section-img.png" alt="results-section-img" style={{ width: 'auto', height: '45vh' }} />
              </a>
            </Paper>
          </Box>

      </Grid>
    </Box>
  );
};

export default PageLayout;
